<i18n lang="yaml">
ja:
  notification:
    message: My Vketの通知を許可しよう！
    body-text: フォローしたユーザーの情報やあなたのルームの訪問者をお知らせします
    deny-button: 後で
    grant-button: 受け取る
    help-link: ※通知を受け取れない場合はこちらをご確認ください
  block:
    message: ブラウザで通知がブロックされています
    body-text: 通知を受け取るには、ブロックの解除が必要です。
    deny-button: 後で
    help-button: 解除方法を確認
en:
  notification:
    message: Allow My Vket to notify you!
    body-text: Notify you of users you follow and visitors to your room
    deny-button: afterwards
    grant-button: receive
    help-link: ※If you do not receive notifications, please click here.
  block:
    message: Your browser is blocking notifications
    body-text: Unblocking is required to receive notifications.
    deny-button: afterwards
    help-button: Find out how to solve the problem
</i18n>

<template>
  <HaBaseDialog
    v-if="isShowRecommend || isShowBlockState"
    class="ho-pwa-recommend-notification"
    @close="denyNotification"
  >
    <template v-if="isShowRecommend">
      <!-- ▼▼▼ 通知許可 ▼▼▼ -->
      <p class="message">{{ i18n.t('notification.message') }}</p>
      <div class="body">
        <p class="text">{{ i18n.t('notification.body-text') }}</p>
        <div class="buttons">
          <HoMyVketButton
            class="button -deny"
            category="gray"
            outline
            @click="denyNotification"
            >{{ i18n.t('notification.deny-button') }}</HoMyVketButton
          >
          <HoMyVketButton
            class="button"
            category="primary"
            @click="grantNotification"
            >{{ i18n.t('notification.grant-button') }}</HoMyVketButton
          >
        </div>
        <p class="help">
          <HaLink :to="helpPageUrl" blank>{{
            i18n.t('notification.help-link')
          }}</HaLink>
        </p>
      </div>
      <!-- ▲▲▲ 通知許可 ▲▲▲ -->
    </template>
    <template v-else-if="isShowBlockState">
      <!-- ▼▼▼ ブロック状況 ▼▼▼ -->
      <p class="message">{{ i18n.t('block.message') }}</p>
      <div class="body">
        <p class="text">{{ i18n.t('block.body-text') }}</p>
        <div class="buttons">
          <HoMyVketButton
            class="button -deny"
            category="gray"
            outline
            @click="denyNotification"
            >{{ i18n.t('block.deny-button') }}</HoMyVketButton
          >
          <HaLink :to="helpPageUrl" blank>
            <HoMyVketButton class="button" category="primary">{{
              i18n.t('block.help-button')
            }}</HoMyVketButton>
          </HaLink>
        </div>
      </div>
      <!-- ▲▲▲ ブロック状況 ▲▲▲ -->
    </template>
  </HaBaseDialog>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
const i18n = useI18n()
const { isGottenMe } = inject(authInjectionKey)!
const { state, subscribe, skipSubscribe } = inject(webPushInjectionKey)!

const isShowRecommend = ref(false)
const isShowBlockState = ref(false)
// リンク先ページは作成中、URLの連絡がきたら差し替え
const helpPageUrl =
  'https://magazine.vket.com/n/n6a80b150a606#3ce2cce4-781e-4cb3-8504-ac0b860c51f3'

const showModal = () => {
  if (
    state.isSupportWebPush &&
    state.isInitialized &&
    !state.isWebPushGranted &&
    state.isConfirmInterval
  ) {
    isShowRecommend.value = true
  }
}

const closeModal = () => {
  isShowRecommend.value = false
  isShowBlockState.value = false
}

const grantNotification = async () => {
  const gtm = useGtm()
  gtm?.trackEvent({
    event: 'enter_notification',
    is_logged_in: isGottenMe.value,
    referrer: process.client ? document?.referrer : '',
  })

  if (Notification.permission === 'denied') {
    // 通知設定が拒否になっている場合、ブロック状況モーダルを表示
    isShowRecommend.value = false
    isShowBlockState.value = true
  } else {
    const response = await subscribe()
    if (response === 'denied') {
      denyNotification()
    } else {
      closeModal()
    }
  }
}

const denyNotification = () => {
  skipSubscribe()
  closeModal()
}

onMounted(() => {
  showModal()
})

watch(state, (newVal) => {
  // 初期化処理の完了を監視（初期化処理が非同期のため）
  if (newVal.isInitialized) {
    showModal()
  }
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-pwa-recommend-notification {
  align-items: flex-start;
  background: transparent;
  pointer-events: none;
  z-index: v.$zindex-toast;

  @include m.sp() {
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: initial;
  }

  :deep(> .dialog-window[class]) {
    box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
    pointer-events: initial;
    width: 500px;

    @include m.sp() {
      border-radius: 5px;
      max-width: min(500px, calc(100% - 32px));
      width: 100%;
    }

    > .icon-container {
      display: none;
      @include m.sp() {
        display: flex;
      }
    }

    > .content {
      padding: 0;
      width: 100%;
    }
  }

  .message {
    background: v.$gray;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: v.$text-body;
    font-size: 16px;
    font-weight: 900;
    padding: v.space(4);
    text-align: center;
    white-space: pre-wrap;
    @include m.sp() {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .body {
    font-size: 14px;
    padding: v.space(4);

    > .buttons {
      align-items: center;
      display: flex;
      gap: v.space(4);
      justify-content: flex-end;
      margin-top: v.space(2);

      .button {
        height: 40px;
        margin: 0;
        @include m.sp() {
          padding-bottom: 0;
          padding-top: 0;
        }

        &.-deny {
          border-width: 0;
          padding: v.space(2);
        }
      }
    }

    > .help {
      margin-top: v.space(2);
    }
  }
}
</style>

<template>
  <transition name="ho-content-loading-transition">
    <div
      v-show="!loaded"
      class="ho-content-loading"
      :class="{ '--loaded': loaded }"
    >
      <div class="container">
        <span
          v-for="(character, index) in 'LOADING...'"
          :key="index"
          class="character"
          >{{ character }}</span
        >
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
const { disableScroll, enableScroll } = useRoot()

interface Props {
  loaded: boolean
}
const props = defineProps<Props>()
const { loaded } = toRefs(props)

watch(loaded, (newVal: boolean, oldVal: boolean) => {
  if (newVal && !oldVal) {
    setTimeout(() => {
      enableScroll()
    }, 300)
  }
})
onMounted(() => {
  if (!loaded.value) {
    disableScroll()
  }
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

$container-fade-speed: 0.3s;

.ho-content-loading {
  background: v.$white;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: v.$zindex-loading;

  .container {
    align-items: center;
    color: v.$gray-5;
    display: flex;
    height: min(100%, calc(100vh - v.$header-height-pc));
    justify-content: center;
    position: sticky;
    top: v.$header-height-pc;
    transition: all $container-fade-speed;
    @include m.sp() {
      height: min(
        100%,
        calc(100vh - (v.$header-height-sp + v.$side-menu-height-sp))
      );
      top: v.$header-height-pc + v.$side-menu-height-sp;
    }

    .character {
      animation: characterAnim 6s infinite ease-in;
      font-size: 24px;
      @for $i from 1 to 20 {
        &:nth-child(#{$i}) {
          animation-delay: #{($i - 1) * 0.2}s;
        }
      }
    }
  }

  &.--loaded {
    .container {
      opacity: 0;
      transform: translateY(20px);
    }
  }
}

.ho-content-loading-transition {
  &-enter-active,
  &-leave-active {
    transition: transform 0.3s ($container-fade-speed + 0.3s) ease-in;
  }

  &-enter,
  &-leave-active {
    transform: translateX(-100%);
  }
}

@keyframes characterAnim {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
</style>

<i18n lang="yaml">
ja:
  talkroom: |
    トーク
    ルーム
  play: |
    イベント/
    ワールド
  home: HOME
  myroom: マイルーム
  store: ストア
  mypage: マイページ
  login: ログイン
  signup: 新規登録
en:
  talkroom: |
    TALK
    ROOM
  play: |
    EVENT/
    WORLD
  home: HOME
  myroom: MY ROOM
  store: STORE
  mypage: MY PAGE
  login: LOGIN
  signup: SIGN UP
</i18n>

<template>
  <nav
    class="ho-sp-toolbar"
    :class="{ '-home': isHome || !isGottenMe, '-safari': styleSafari }"
  >
    <ul class="items">
      <li v-for="item in items" :key="item.id" class="item">
        <template v-if="item.id === 'mypage'">
          <template v-if="isGottenMe">
            <!-- ▼▼▼ マイページ ▼▼▼ -->
            <HaLink :to="item.link">
              <HaBaseButton
                class="link"
                :class="[`-${item.id}`, { '-current': isCurrent(item.id) }]"
              >
                <span class="icon">
                  <HmUserIcon :src="iconUrl" />
                </span>
                <span class="label">{{ i18n.t(item.id) }}</span>
              </HaBaseButton>
            </HaLink>
            <!-- ▲▲▲ マイページ ▲▲▲ -->
          </template>
          <template v-else>
            <!-- ▼▼▼ ログインボタン ▼▼▼ -->
            <HaBaseButton class="link" @click="onClickLogin">
              <span class="icon">
                <IconLogin />
              </span>
              <span class="label">{{ i18n.t('login') }}</span>
            </HaBaseButton>
            <!-- ▲▲▲ ログインボタン ▲▲▲ -->
          </template>
        </template>

        <template v-else-if="item.id === 'home'">
          <template v-if="isHome && isGottenMe">
            <!-- ▼▼▼ ホーム（サイトトップ時） ▼▼▼ -->
            <HaBaseButton class="home-link" @click="enterMyRoom">
              <span class="icon">
                <IconMyroom />
              </span>
              <span class="label">{{ i18n.t('myroom') }}</span>
            </HaBaseButton>
            <!-- ▲▲▲ ホーム（サイトトップ時） ▲▲▲ -->
          </template>
          <template v-else-if="!isGottenMe">
            <!-- ▼▼▼ 新規登録（サイトトップ未ログイン時） ▼▼▼ -->
            <HaBaseButton class="home-link" @click="onClickLogin">
              <span class="icon">
                <IconLogoMyvket />
              </span>
              <span class="label">{{ i18n.t('signup') }}</span>
            </HaBaseButton>
            <!-- ▲▲▲ 新規登録（サイトトップ未ログイン時） ▲▲▲ -->
          </template>
          <template v-else>
            <!-- ▼▼▼ ホーム ▼▼▼ -->
            <HaLink
              :to="item.link"
              class="link"
              :class="[`-${item.id}`, { '-current': isHome }]"
            >
              <span class="icon">
                <IconHome />
              </span>
              <span class="label">{{ i18n.t(item.id) }}</span>
            </HaLink>
            <!-- ▲▲▲ ホーム ▲▲▲ -->
          </template>
        </template>

        <template v-else>
          <!-- ▼▼▼ 通常 ▼▼▼ -->
          <HaLink
            :to="item.link"
            class="link"
            :class="[`-${item.id}`, { '-current': isCurrent(item.id) }]"
          >
            <span class="icon">
              <template v-if="item.id === 'talkroom'">
                <IconFriend />
              </template>
              <template v-else-if="item.id === 'play'">
                <IconPlay />
              </template>
              <template v-else-if="item.id === 'store'">
                <IconShop />
              </template>
            </span>
            <span class="label">{{ i18n.t(item.id) }}</span>
          </HaLink>
          <!-- ▲▲▲ 通常 ▲▲▲ -->
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import IconFriend from '@/assets/icons/icon_friend.svg'
import IconPlay from '@/assets/icons/icon_play.svg'
import IconMyroom from '@/assets/icons/icon_myroom.svg'
import IconHome from '@/assets/icons/icon_home.svg'
import IconShop from '@/assets/icons/icon_shop.svg'
import IconLogin from '@/assets/icons/icon_login.svg'
import IconLogoMyvket from '@/assets/icons/icon_logo_myvket.svg'

const i18n = useI18n()
const route = useRoute()
const toast = useToast()

const { me, isGottenMe, login } = inject(authInjectionKey)!
const { state: profileState } = inject(myProfileInjectionKey)!
const styleSafari = ref(false)
const { enterInGame } = useInGame()
const { addToast } = toast

const items = [
  {
    id: 'talkroom',
    link: '/talkroom',
  },
  {
    id: 'play',
    link: '/play/event',
  },
  {
    id: 'home',
    link: '/',
  },
  {
    id: 'store',
    link: '/cloudstore',
  },
  {
    id: 'mypage',
    link: '/mypage',
  },
] as const

const isHome = computed(() => route.name?.toString().includes('index'))
const iconUrl = computed(() => profileState.value.profile?.icon?.url ?? '')

const enterMyRoom = () => {
  if (me.value) {
    enterInGame({
      worldId: `MyRoom-${me.value.vketId}`,
    })
  }
}

const isCurrent = (id: string) => {
  return route.name?.toString().includes(id)
}

onMounted(() => {
  styleSafari.value = isSafari() || false
})

const onClickLogin = async () => {
  const result = await login()
  if (result) {
    addToast(i18n.t(result), 'error')
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-sp-toolbar {
  background: v.$base-background-color;
  border-top: 1px solid v.$gray-1;
  bottom: 0;
  box-sizing: border-box;
  display: none;
  filter: drop-shadow(0 0 5px rgba(38, 38, 38, 0.2));
  height: v.$sp-toolbar-height;
  left: 0;
  position: fixed;
  right: 0;
  z-index: v.$zindex-sp-toolbar;

  &.-safari {
    @media (display-mode: standalone) {
      height: v.$sp-toolbar-height-pwa-safari;
    }
  }
  @include m.tb() {
    display: block;
  }

  > .items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    height: 100%;

    > .item {
      height: 100%;
    }
  }

  .link {
    align-items: center;
    color: v.$text-link;
    display: flex;
    fill: v.$text-link;
    flex-direction: column;
    gap: 2px;
    height: 100%;
    padding-top: v.space(2);
    text-align: center;
    transition: color 0.2s, fill 0.2s;
    width: 100%;

    &:hover,
    &.-current {
      &.-talkroom {
        color: v.$talkroom-color;
        fill: v.$talkroom-color;
      }

      &.-play {
        color: v.$play-color;
        fill: v.$play-color;
      }

      &.-home {
        color: v.$primary-color;
        fill: v.$primary-color;
      }

      &.-store {
        color: v.$store-color;
        fill: v.$store-color;
      }

      > .icon {
        opacity: 1;
      }

      > .label {
        opacity: 1;
      }
    }

    > .icon {
      height: 25px;
      opacity: 0.6;
      width: 25px;
    }

    > .label {
      align-items: center;
      display: flex;
      font-size: 10px;
      height: 28px;
      justify-content: center;
      line-height: 1.2;
      opacity: 0.6;
      white-space: pre-wrap;
    }

    // マイページ
    &.-mypage {
      > .icon {
        height: 30px;
        opacity: 1;
        position: relative;
        width: 30px;
        z-index: 1;
        // NOTE: ボーダーが付かない時のアイコンの上端を他のアイコンと合わせるために、疑似要素を利用する方法でコーディングしています
        &::after {
          background: transparent;
          border-radius: 50%;
          bottom: -2px;
          content: '';
          left: -2px;
          position: absolute;
          right: -2px;
          top: -2px;
          transition: background-color 0.2s, filter 0.2s;
          z-index: -1;
        }
      }

      > .label {
        // NOTE: 他アイコンとのラベルのラインを揃える目的でこちらの高さを設定しています
        height: 18px;
      }

      &:hover,
      &.-current {
        color: v.$primary-color;

        > .icon {
          &::after {
            background: v.$primary-color;
            filter: drop-shadow(0 0 4px v.$primary-color);
          }
        }
      }
    }
  }

  // サイトトップ時
  &.-home {
    border-top-color: transparent;
    filter: none;

    > .items {
      grid-template-columns: 1fr 1fr max(1fr, 90px) 1fr 1fr;
    }

    .home-link {
      align-items: center;
      background: v.$primary-color;
      border: 10px solid v.$white;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      font-size: 10px;
      height: 90px;
      justify-content: center;
      margin: -30px auto 0;
      max-height: none;
      width: 90px;

      > .icon {
        fill: v.$white;
        height: 36px;
        width: 36px;
      }

      > .label {
        color: v.$white;
        flex-shrink: 0;
        height: 20px;
      }
    }
  }
}
</style>

import type { InjectionKey } from 'vue'

export type pwaRecommendStateType = {
  isAbleInstall: boolean
  isInstallDismissed: boolean
  isInitialized: boolean
}

export const usePwaRecommend = () => {
  const state = reactive<pwaRecommendStateType>({
    isAbleInstall: false,
    isInstallDismissed: false,
    isInitialized: false,
  })
  const pwaEvent = ref<BeforeInstallPromptEvent | null>(null)
  // インストール確認インターバル：日（day）
  const installConfirmInterval = 1
  // インストール確認インターバル（最大拒否回数以上拒否された時）：日（day）
  const installConfirmIntervalLong = 30
  // 最大拒否回数
  const maxDismissCount = 3

  /**
   * PWA通知関連のユーザー状況初期化
   */
  const init = () => {
    setPwaEvent()
    checkIsInstallDismissed()
    // 初期化完了フラグを更新
    state.isInitialized = true
  }

  /**
   * PWAイベント登録
   */
  const setPwaEvent = () => {
    // PWAのインストールを促すイベントを登録する
    window.addEventListener('beforeinstallprompt', (e) => {
      if ('prompt' in e) {
        e.preventDefault()
        pwaEvent.value = e as BeforeInstallPromptEvent
        state.isAbleInstall = true
      } else {
        console.error('beforeinstallprompt is not supported')
      }
    })
  }

  /**
   * インストールポップアップ表示
   */
  const showInstallPrompt = async () => {
    if (pwaEvent.value) {
      pwaEvent.value.prompt()
      const response = await pwaEvent.value.userChoice
      if (response.outcome === 'accepted') {
        removeLocalStorageValue(PWA_INSTALL_DISMISS_COUNT_KEY)
      }
      pwaEvent.value = null
      state.isAbleInstall = false
      return response
    } else {
      return false
    }
  }

  /**
   * インストール確認インターバル以降のアクセスか確認
   */
  const checkIsInstallDismissed = () => {
    const lastConfirmDateStr = getLocalStorageValue(
      PWA_INSTALL_LAST_CONFIRM_KEY
    )
    if (!lastConfirmDateStr) {
      // ストレージに対象の値が設定されていない場合、（インストール見送りされていないので）falseを設定
      state.isInstallDismissed = false
    } else {
      const dismissCountStr = getLocalStorageValue(
        PWA_INSTALL_DISMISS_COUNT_KEY
      )
      if (dismissCountStr && Number(dismissCountStr) >= maxDismissCount) {
        const nextConfirmDate = addDateTime(
          installConfirmIntervalLong,
          'day',
          lastConfirmDateStr
        )
        state.isInstallDismissed = !isAfterTargetDate(nextConfirmDate)
      } else {
        const nextConfirmDate = addDateTime(
          installConfirmInterval,
          'day',
          lastConfirmDateStr
        )
        state.isInstallDismissed = !isAfterTargetDate(nextConfirmDate)
      }
    }
  }

  /**
   * インストール確認ストレージを設定
   */
  const setInstallConfirm = () => {
    const date = new Date()
    const dateStr = formatDateTimeLocal(date)
    setLocalStorageValue(PWA_INSTALL_LAST_CONFIRM_KEY, dateStr)
  }

  /**
   * 通知許可の拒否カウントを設定
   */
  const setInstallDismissCount = () => {
    const dismissCountStr = getLocalStorageValue(PWA_INSTALL_DISMISS_COUNT_KEY)
    if (!dismissCountStr) {
      // 拒否されていない場合
      setLocalStorageValue(PWA_INSTALL_DISMISS_COUNT_KEY, '1')
    } else {
      const deniedCount = Number(dismissCountStr)
      setLocalStorageValue(
        PWA_INSTALL_DISMISS_COUNT_KEY,
        (deniedCount + 1).toString()
      )
    }
  }

  /**
   * インストール確認関連の情報更新
   * TODO: シンプルな処理なので、関数でくくる必要ないかもしれない。HoPwaRecommendInstallの「通知拒否時のメソッドをまとめる」対応の際に再考する
   */
  const updateState = () => {
    checkIsInstallDismissed()
  }

  const skipInstall = () => {
    setInstallConfirm()
    setInstallDismissCount()
    updateState()
  }

  return {
    state: readonly(state),
    init,
    showInstallPrompt,
    setInstallConfirm,
    setInstallDismissCount,
    updateState,
    skipInstall,
  }
}

export type UsePwaRecommendComposable = ReturnType<typeof usePwaRecommend>

export const pwaRecommendInjectionKey: InjectionKey<UsePwaRecommendComposable> =
  Symbol('pwaRecommend')

<i18n lang="yaml">
ja:
  install:
    message: ホーム画面から簡単にMyVketを起動できるようにしよう！
    message-sp: スマホのホーム画面から簡単にMy Vketを起動できるようにしよう！
    dismiss-button: 後で
    install-button: ホーム画面に追加
    ios-note-front: ''
    ios-note-back: をタップして「ホームに追加」で完了！
en:
  install:
    message: Let's make it easy to launch MyVket from the home screen!
    message-sp: Make it easy to start My Vket from the home screen of your phone!
    dismiss-button: afterwards
    install-button: Add to Home Screen
    ios-note-front: Tap
    ios-note-back: and "Add to Home" to complete!
</i18n>

<template>
  <div v-if="isShowToast" class="ho-pwa-recommend-install">
    <div class="content-container">
      <div class="content" :class="{ '-ios': deviceIos }">
        <HaBaseButton class="close" @click="dismissInstall"
          ><IconXmark class="icon"
        /></HaBaseButton>
        <template v-if="deviceIos">
          <!-- ▼▼▼ iOSの場合 ▼▼▼ -->
          <div class="ios-inner">
            <div class="message">
              <div class="image-container">
                <HaBaseImage src="/images/pwa/ios_install.webp" class="image" />
              </div>
              <p>{{ i18n.t('install.message-sp') }}</p>
            </div>
            <div class="note" :class="{ '-en': !isJa }">
              <template v-if="isJa">
                <IconPwaIosInstall class="icon" />
                <span>{{ i18n.t('install.ios-note-back') }}</span>
              </template>
              <template v-else>
                <span>{{ i18n.t('install.ios-note-front') }}</span>
                <IconPwaIosInstall class="icon" />
                <span>{{ i18n.t('install.ios-note-back') }}</span>
              </template>
            </div>
          </div>
          <!-- ▲▲▲ iOSの場合 ▲▲▲ -->
        </template>
        <!-- NOTE: 最初v-elseで実装していたが表示が安定せず、v-ifを利用したら安定したためこちらで実装 -->
        <template v-if="!deviceIos">
          <!-- ▼▼▼ iOS以外の場合 ▼▼▼ -->
          <div class="default-inner">
            <div class="message">
              <div class="image">
                <IconLogoMyvket class="icon" />
              </div>
              <p>
                <template v-if="isMobile()">{{
                  i18n.t('install.message-sp')
                }}</template
                ><template v-else>{{ i18n.t('install.message') }}</template>
              </p>
            </div>
            <div class="buttons" :class="{ '-en': !isJa }">
              <HoMyVketButton
                class="button -dismiss"
                category="gray"
                outline
                @click="dismissInstall"
                >{{ i18n.t('install.dismiss-button') }}</HoMyVketButton
              >
              <HoMyVketButton
                class="button -install"
                category="primary"
                @click="install"
                >{{ i18n.t('install.install-button') }}</HoMyVketButton
              >
            </div>
          </div>
          <!-- ▲▲▲ iOS以外の場合 ▲▲▲ -->
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
import IconXmark from '@/assets/icons/icon-xmark.svg'
import IconLogoMyvket from '@/assets/icons/icon_logo_myvket.svg'
import IconPwaIosInstall from '@/assets/icons/icon_pwa_ios_install.svg'

import { isIos, isSafari, isMobile, isPwa } from '@/utils/user-agent'

const gtm = useGtm()
const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')
const { isGottenMe } = inject(authInjectionKey)!
const { state, showInstallPrompt, skipInstall } = inject(
  pwaRecommendInjectionKey
)!

const isShowToast = ref(false)

const showToast = () => {
  // PWAで起動した場合は表示しない
  if (isPwa()) return

  const isiOSSafari = deviceIos.value && isSafari()
  if (
    state.isInitialized && // 初期化済み
    (state.isAbleInstall || isiOSSafari) && // インストール可能デバイス
    !state.isInstallDismissed // インストール拒否していない
  ) {
    isShowToast.value = true
  }
}

const hideToast = () => {
  isShowToast.value = false
}

const install = async () => {
  gtm?.trackEvent({
    event: 'enter_PWA',
    is_logged_in: isGottenMe.value,
    referrer: process.client ? document?.referrer : '',
  })
  const response = await showInstallPrompt()
  if (response && response.outcome === 'dismissed') {
    dismissInstall()
  } else {
    hideToast()
  }
}

const deviceIos = computed(() => isIos())

const dismissInstall = () => {
  skipInstall()
  hideToast()
}

onMounted(() => {
  showToast()
})

watch(state, (newVal) => {
  // beforeinstallpromptの発火を監視
  if (newVal.isAbleInstall) {
    showToast()
    return
  }

  const isiOSSafari = deviceIos.value && isSafari()
  if (newVal.isInitialized && isiOSSafari) {
    showToast()
  }
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-pwa-recommend-install {
  animation: fadeInFromRight 1s both;
  background: v.$gray-3;
  border-top-left-radius: 10px;
  bottom: 0;
  max-width: 520px;
  padding: v.space(6);
  position: fixed;
  right: 0;
  width: 35%;
  z-index: v.$zindex-toast;

  @include m.tb() {
    animation: fadeInFromBottom 1s both;
    border-top-left-radius: 0;
    display: flex;
    justify-content: center;
    left: 0;
    max-width: none;
    padding: v.space(4);
    width: 100%;
  }

  > .content-container {
    container-name: content-container;
    container-type: inline-size;

    width: 100%;
    @include m.sp() {
      max-width: 380px;
    }

    > .content {
      background: v.$white;
      border-radius: 5px;
      box-shadow: 0 0 16px 0 v.$gray-8;
      padding: v.space(4) v.space(8);
      position: relative;
      width: 100%;

      &.-ios {
        &::after {
          background: v.$white;
          clip-path: polygon(0 0, 100% 0, 50% 100%);
          content: '';
          display: block;
          height: 16px;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          top: calc(100% - 1px);
          width: 24px;
        }
      }

      > .close {
        align-items: center;
        background: v.$white;
        border: 1px solid v.$gray;
        border-radius: 50%;
        display: flex;
        height: 32px;
        justify-content: center;
        position: absolute;
        right: -8px;
        top: -8px;
        width: 32px;

        > .icon {
          fill: v.$gray-4;
          height: 24px;
          width: 24px;
        }
      }

      @container content-container (max-width: 720px) {
        padding: v.space(4);
      }
    }
  }

  .default-inner {
    align-items: center;
    display: flex;
    gap: v.space(8);
    justify-content: space-between;

    > .message {
      align-items: center;
      display: flex;
      font-weight: bold;
      gap: v.space(4);

      > .image {
        flex-shrink: 0;
        height: 48px;
        width: 48px;

        > .icon {
          fill: v.$primary-color;
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }

    > .buttons {
      display: flex;
      flex-shrink: 0;
      gap: v.space(4);

      > .button {
        height: 40px;
        padding: v.space(2);

        &.-dismiss {
          border-width: 0;
          width: 56px;
        }

        &.-install {
          width: 192px;
        }
      }

      &.-en {
        > .button {
          &.-dismiss {
            width: 100px;
          }
        }
      }
    }
  }

  .ios-inner {
    display: flex;
    flex-direction: column;
    gap: v.space(2);

    > .message {
      align-items: center;
      display: flex;
      font-size: 14px;
      gap: v.space(4);

      > .image-container {
        flex-shrink: 0;
        height: 60px;
        width: 80px;

        > .image {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }

    > .note {
      background: v.$gray;
      font-size: 12px;
      line-height: 20px;
      padding: v.space(4);
      text-align: center;

      > .icon {
        display: inline-block;
        height: 20px;
        margin-right: v.space(1);
        vertical-align: text-bottom;
        width: 20px;
      }

      &.-en {
        > .icon {
          margin-left: v.space(1);
        }
      }
    }
  }

  @container content-container (max-width: 600px) {
    .default-inner {
      flex-direction: column;
      gap: v.space(4);

      > .message {
        font-size: 14px;
        font-weight: normal;

        > .image {
          height: 40px;
          width: 40px;
        }
      }

      > .buttons {
        width: 100%;

        > .button {
          height: 36px;

          &.-dismiss {
            flex-shrink: 0;
          }

          &.-install {
            flex-grow: 1;
            width: 100%;
          }
        }
      }
    }
  }
}

@keyframes fadeInFromRight {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInFromBottom {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
